body {
  margin: 0;
  padding: 0;
  font-family: 'Karla',sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important
}

/* INIT CUSTOM FLAG COUNTRY SELECT */

.flag-select .selected--flag--option {
  letter-spacing: 1px;
}

/* END CUSTOM FLAG COUNTRY SELECT */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}

.Select-menu-outer {
  z-index: 999 !important;
}
.amplify-button[data-variation='primary'] {
  --amplify-components-button-primary-background-color: #2494db;
}

